import React from 'react';
import { div, Dot } from 'pure-react-carousel';
import { useHistory } from 'react-router-dom';
import LandingPageImg from "./carouselImages/landingPage.JPG"
import backarrow from './carouselImages/backArrow.png';

if (typeof window !== 'undefined') {
  import('pure-react-carousel/dist/react-carousel.es.css');
}
import css from './HeroCarousel.module.css';

const CustomDotGroup = ({ className, totalSlides }) => (
  <div className={`${css.dotGroup} ${className}`}>
    {Array.from({ length: totalSlides }).map((_, index) => (
      <Dot key={index} slide={index} className={css.dot} selectedClassName={css.dotSelected} />
    ))}
  </div>
);

const HeroCarousel = () => {
  const history = useHistory();

  const navigateToSearch = () => {
    history.push('/s');
  };

  return (
    <div className={css.provider}>
      <div className={css.slider}>
        <div className={css.slide}>
          <img
            src={LandingPageImg}
            alt={'clothing'}
            className={css.img}
          />
          <div className={css.container}>
            <h1 className={css.text}>
              <span>Discover hidden </span>
              <br />
              <span>gems near you</span>
            </h1>
            <button onClick={navigateToSearch}>START SHOPPING

              <img src={backarrow} alt='start' className={css.backarrow}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroCarousel;
