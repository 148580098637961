import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import { Link } from 'react-router-dom';

if (typeof window !== 'undefined') {
  import('pure-react-carousel/dist/react-carousel.es.css');
}

import css from './CommunityCarousel.module.css';
import backarrow from './carouselImages/backArrow.png';
import { NamedLink, ResponsiveImage } from '../../../components';
import { createSlug } from '../../../util/urlHelpers';
import { AVATAR_IMAGE_VARIANTS, AVATAR_SIZES_LARGE } from '../../../components/Avatar/Avatar';

export default class CommunityCarousel extends React.Component {
  render() {
    const { smallBusinesses, includedImages } = this.props;
    const filteredSmallBusiness = smallBusinesses.length > 10 ? smallBusinesses.slice(0, 10) : smallBusinesses
    return (
      <>
        <CarouselProvider
          naturalSlideWidth={100}
          naturalSlideHeight={100}
          totalSlides={filteredSmallBusiness.length}
          visibleSlides={filteredSmallBusiness.length > 3 ? 3 : filteredSmallBusiness.length}
          step={1}
          isPlaying={true}
          interval={5000}
          infinite={true}
          className={css.provider}
        >
          {filteredSmallBusiness.length > 3 && <div className={css.arrowContainer}>
            <ButtonBack className={css.btn}>
              <img src={backarrow} alt={'back arrow'} style={{ width: '30px', height: 'auto' }} />
            </ButtonBack>
            <ButtonNext className={css.btn}>
              <img src={backarrow} alt={'next arrow'} style={{ width: '30px', height: 'auto' }} className={css.nextBackBtn} />
            </ButtonNext>{' '}
          </div>}
          <div className={css.sliderContainer}>
            <Slider className={css.slider}>
              {filteredSmallBusiness &&
                filteredSmallBusiness.length &&
                filteredSmallBusiness?.map((seller, index) => {
                  const { title = '' } = seller.attributes;
                  const slug = createSlug(title);
                  const imageData = includedImages.find(image => image.id.uuid === seller.relationships.profileImage.data.id.uuid)
                  return (
                    <Slide index={index} key={index} className={css.slideborder}>
                      <div className={css.slide}>
                        <NamedLink name="ProfilePage" params={{ id: seller?.id.uuid, slug }}>
                          <span className={css.profileLink}>VIEW STORE</span>
                          <ResponsiveImage
                            alt={'communityImage'}
                            image={imageData}
                            variants={AVATAR_IMAGE_VARIANTS}
                            sizes={AVATAR_SIZES_LARGE}
                          />
                          <div className={css.label}>
                            <div className={css.labelMain}>
                              <h3>{seller.attributes.profile.publicData.storeName || seller.attributes.profile.displayName}</h3>
                              <p>{seller.attributes.profile.publicData.storeNeighborhood}</p>
                            </div>
                            <div className={css.arrowImg}>
                              <img src={backarrow} alt='Arrow' />
                            </div>
                          </div>
                        </NamedLink>
                      </div>
                    </Slide>
                  );
                })}
            </Slider>
          </div>
        </CarouselProvider>
      </>
    );
  }
}
