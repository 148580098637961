import React from 'react';
import css from './NeighborhoodsSection.module.css';

const Neighborhoods = () => {
  return (
    <>
      <div className={css.howWorkContainer}>
        <h1>HOW IT WORKS</h1>
        <table>
          <tbody>
            <td>
              <div className={css.buttonContainer}>
                <button type="button">
                  <p className={css.numberButton}>01</p>
                  <p className={css.buttonDescription}>DISCOVER YOUR UNIQUE GEM</p>
                </button>
             
              <p className={css.worksDetail}>
                Locago has the latest and greatest items at boutiques near you.
              </p>
              </div>
            </td>
            <td>
              <div className={css.buttonContainer}>
                <button type="button">
                  <p className={css.numberButton}>02</p>
                  <p className={css.buttonDescription}>PICK UP YOUR ORDER IN STORE</p>
                </button>
                <p className={css.worksDetail}>
                  After you’ve made the purchase, head into store to pick up your order.
                </p>
              </div>
            </td>
            <td>
              <div className={css.buttonContainer}>
                <button type="button">
                  <p className={css.numberButton}>03</p>
                  <p className={css.buttonDescription}>SUPPORT YOUR COMMUNITY</p>
                </button>
                <p className={css.worksDetail}>Meet the store owner and come back for more.</p>
              </div>
            </td>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Neighborhoods;
