import React from 'react';
import StaticPage from '../PageBuilder/StaticPage';
import { LayoutComposer } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import css from './LandingPage.module.css';
import { useEffect, useState } from 'react';
// sections
import HeroCarousel from './carousels/HeroCarousel';
import CommunityCarousel from './carousels/CommunityCarousel';
import FooterSection from './FooterSection/Footer';
import { getTotalCommunityMember } from '../../util/api';

// // icons
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import Neighborhoods from './Neighborhoods/NeighborhoodsSection';

const LandingPage = () => {
  const config = useConfiguration();

  const layoutAreas = `
    topbar
    main
    footer
  `;
  const { logoImageDesktop } = config.branding;

  const [smallBusinesses, setSmallBusinesses] = useState([]);
  const [includedImages, setIncludedImages] = useState([]);
  useEffect(() => {
    const fetchCommunityMember = async () => {
      try {
        const response = await getTotalCommunityMember(JSON.stringify('empty request'));
        setSmallBusinesses(response.data);
        setIncludedImages(response.included);
      } catch (error) {
        console.error('Error fetching the locations of stores: ', error);
      }
    };

    fetchCommunityMember();
  }, []);

  const neighborhoodCarouselText = 'SHOP WITHIN YOUR NEIGHBORHOOD';

  return (
    <StaticPage
      className={css.root}
      title="Locago Landing Page"
      schema={{
        '@context': 'http://schema.org',
        '@type': 'web page',
        description: 'Description of this page',
        name: 'Locago Landing Page',
      }}
    >
      <LayoutComposer areas={layoutAreas} className={css.layout}>
        {props => {
          const { Topbar, Main, Footer } = props;
          return (
            <>
              <TopbarContainer currentPage="LandingPage" />
              <Main>
                <HeroCarousel />
                <Neighborhoods />
                <section className={css.carouselsection}>
                  <h2><span>Shop Chicago</span> <span>Small Businesses</span></h2>
                  <CommunityCarousel
                    smallBusinesses={smallBusinesses}
                    logoImageDesktop={logoImageDesktop}
                    includedImages={includedImages}
                  />
                  <div className={css.shopNeighbourhoodContainer}>
                    {/* this is for animation SHOP WHITHIN THE NEIGHBORHOOD */}
                    <div className={css.scrollAnimateContainer}>
                      <div>
                        {[...Array(6)].map((_, index) => (
                          <h1 key={index}>
                            {neighborhoodCarouselText.split(' ').map((word, i) => (
                              <span key={i}>{word} </span>
                            ))}
                          </h1>
                        ))}
                      </div>
                    </div>
                  </div>

                  <div className={css.shopLocallyContainer}>
                    <h1>WHY SHOP LOCALLY?</h1>
                    <p>
                      Small businesses are the backbone of our communities and carry unique
                      treasures. We started Locago because there’s something special about showing
                      off that one-of-a-kind item to your friends.
                    </p>
                  </div>
                </section>
              </Main>
              <Footer>
                <FooterSection />
              </Footer>
            </>
          );
        }}
      </LayoutComposer>
    </StaticPage>
  );
};

export default LandingPage;
